<template>
	<div class="ele-body" style="padding:0">
		<div style="width:100%;height:100%;background:rgba(0,0,0,0.3);position:fixed;top:0;left:0;right:0;z-index:999"
			v-if="isActive" @click="handleClose"></div>
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" label-width="77px" class="ele-form-search " style="display:flex;flex-wrap: wrap;"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<el-form-item label="省:" label-width="35px" class="w-150 mt-10">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省'
						clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150 mt-10">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市'
						clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="62px" class="w-200 mt-10">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="开始时间:" label-width="76px" class="w-300 mt-10">
					<!-- 	<el-date-picker style="width:200px" v-model="dataIndex==0?(table.where.start_time==''):(table.where.start_time)" value-format="yyyy-MM-dd" type="date"
						placeholder="搜索开始时间" clearable> -->
					<el-date-picker style="width:200px" v-model="table.where.start_time" value-format="yyyy-MM-dd"
						type="date" placeholder="搜索开始时间" clearable>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间:" label-width="76px" class="w-300 mt-10">
					<el-date-picker style="width:200px" v-model="table.where.end_time" value-format="yyyy-MM-dd" type="date"
						placeholder="搜索结束时间" clearable>
					</el-date-picker>
				</el-form-item>
				<div class="dayBox mt-10">
					<!-- <div v-for="(item,index) in timeSearch" :key="index" :label="index" :class="dataIndex==1?'greenActive':'dayStyle'" style="margin-left:20px" @click="handledate(1)">{{param2.yesterday}}</div> -->
					<div :class="(dataIndex == 1 && !table.where.start_time && !table.where.end_time) ? 'greenActive' : 'dayStyle'"
						@click="handledate(1)">{{ param.data }}</div>
					<div :class="(dataIndex == 2 && !table.where.start_time && !table.where.end_time) ? 'greenActive' : 'dayStyle'"
						@click="handledate(2)">{{ param.yesterday }}</div>
					<div :class="(dataIndex == 3 && !table.where.start_time && !table.where.end_time) ? 'greenActive' : 'dayStyle'"
						@click="handledate(3)">{{ param.week }}</div>
					<div :class="(dataIndex == 4 && !table.where.start_time && !table.where.end_time) ? 'greenActive' : 'dayStyle'"
						@click="handledate(4)" style="margin-right:20px;">{{ param.month }}</div>
				</div>
				<div class=" mt-10">
					<el-button type="primary" style="border-radius: 3px;" @click="getTimeSearch"
						class="ele-btn-icon ele-form-actions">搜索
					</el-button>
					<el-button type="primary" style="border-radius: 3px;" @click="handleChangePositioning"
						class="ele-btn-icon ele-form-actions">
						司机/跑腿当前位置</el-button>
					<el-button type="primary" style="border-radius: 3px;" @click="handleChangeOnline"
						class="ele-btn-icon ele-form-actions">历史在线数据
					</el-button>
				</div>

			</el-form>
			<div ref="table" class="dataTable">

				<el-row :gutter="15">
					<!--今日总订单-->
					<el-col :md="8" :sm="12">
						<el-card class="box-card2">
							<div slot="header" class="clearfix el-card__header1">
								<span>{{ dataIndex == 1 ? '今日' : (dataIndex == 2 ? '昨日' : (dataIndex == 3 ? '本周' : (dataIndex == 4 ? '本月' : ''))) }}总订单</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox greenBackground">
									<img class="orderImg" src="../../assets/icon/order.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ orderData.all_order }}</span> 笔</div>
							<div class="d-flex">
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">成交订单：{{ orderData.success_order }}笔</div>
								</div>
								<div class="orderText">
									<div class="circle red"></div>
									<div class="">取消订单：{{ orderData.cancel_order }}笔</div>
								</div>
								<div class="orderText">
									<div class="circle gray"></div>
									<div class="">未接订单：{{ orderData.missed_order }}笔</div>
								</div>
								<!-- <div class="orderText">
									<div class="circle black"></div>
									<div class="">预约订单：{{ orderData.taxi_hailing_order }}笔</div>
								</div> -->
							</div>

						</el-card>
					</el-col>
					<!--今日新用户-->
					<el-col :md="8" :sm="12">
						<el-card class="box-card2">
							<div slot="header" class="clearfix">
								<span>{{ dataIndex == 1 ? '今日' : (dataIndex == 2 ? '昨日' : (dataIndex == 3 ? '本周' : (dataIndex == 4 ? '本月' : ''))) }}新用户</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox redBackground">
									<img class="orderImg" src="../../assets/icon/user.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ userData.new_user }}</span> 人</div>
							<div class="d-flex">
								<div class="orderText">
									<div class="circle red"></div>
									<div class="">推荐用户：{{ userData.new_recommend_user }}人</div>
								</div>
								<div class="orderText">
									<div class="circle gray"></div>
									<div class="">自主用户：{{ userData.autonomy_user }}人</div>
								</div>
							</div>

						</el-card>
					</el-col>
					<!--今日新商家-->
					<el-col :md="8" :sm="12">
						<el-card class="box-card2">
							<div slot="header" class="clearfix">
								<span>{{ dataIndex == 1 ? '今日' : (dataIndex == 2 ? '昨日' : (dataIndex == 3 ? '本周' : (dataIndex == 4 ? '本月' : ''))) }}新商家</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox blueBackground">
									<img class="orderImg" src="../../assets/icon/merchants.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ merchantData.new_merchant }}</span> 位</div>
							<div class="d-flex">
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">跑腿新入驻：{{ merchantData.new_legwork }}位</div>
								</div>
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">超市新入驻：{{ merchantData.new_market }}位</div>
								</div>
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">叫餐新入驻：{{ merchantData.new_food }}位</div>
								</div>
							</div>

						</el-card>
					</el-col>
					<!--今日新司机-->
					<el-col :md="8" :sm="12">
						<el-card class="box-card2">
							<div slot="header" class="clearfix">
								<span>{{ dataIndex == 1 ? '今日' : (dataIndex == 2 ? '昨日' : (dataIndex == 3 ? '本周' : (dataIndex == 4 ? '本月' : ''))) }}新司机</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox blueBackground">
									<img class="orderImg" src="../../assets/icon/driver.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ driverData.check_total_driver }}</span> 位</div>
							<div class="d-flex">
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">审核通过：{{ driverData.checked_new_driver }}位</div>
								</div>
								<div class="orderText">
									<div class="circle red"></div>
									<div class="">待审核：{{ driverData.check_new_driver }}位</div>
								</div>
							</div>

						</el-card>
					</el-col>
					<!--7天活跃量-->
					<el-col :md="8" :sm="12">
						<el-card class="box-card2">
							<div slot="header" class="clearfix">
								<span>7天活跃量</span>
								<el-collapse style="float:right" @change="handleChange11">
									<el-collapse-item>
										<template slot="title">
											<img style="float:right;width:16px;" src='../../assets/icon/circle.png' />
										</template>
									</el-collapse-item>
									<div class="seventActivity activeData" style="width: 500px;" v-if="isSevent">
										<div class="seventActivityBox">
											<div style="width: 350px;">各地区7天活跃量</div>
											<div style="display: flex;width: 330px;">
												<el-button @click="handleChange11" style="margin-left:-20px;">全平台
												</el-button>
												<el-select class="selectStyle" style="width: 400px;" v-model="param1.aid"
													@change="handleSelect(param1.aid)">
													<el-option v-for="option in areaData" :value="option.aid"
														:key="option.aid" :label="option.aname"></el-option>
												</el-select>
											</div>
										</div>
										<div class="seventBox" v-for="item in activeData" :key="item.id">
											<div class="seventTitle">
												<img src="../../assets/icon/position.png" alt="" class="positionImg" />
												<div>{{ item.name }}</div>
											</div>
											<div class="activityBox">
												<div class="seventContent">
													<div>用户</div>
													<div class="seventJindu" :style="{ width: item.seventWidth1 }"></div>
													<div>{{ item.user_active_volume }}人</div>
												</div>
												<div class="seventContent">
													<div>司机</div>
													<div class="seventJindu" :style="{ width: item.seventWidth2 }"></div>
													<div>{{ item.driver_active_volume }}人</div>
												</div>
												<div class="seventContent">
													<div>跑腿</div>
													<div class="seventJindu" :style="{ width: item.seventWidth3 }"></div>
													<div>{{ item.legwork_active_volume }}人</div>
												</div>
												<div class="seventContent">
													<div>超市</div>
													<div class="seventJindu" :style="{ width: item.seventWidth4 }"></div>
													<div>{{ item.mrchant_m }}人</div>
												</div>
												<div class="seventContent">
													<div>叫餐</div>
													<div class="seventJindu" :style="{ width: item.seventWidth5 }"></div>
													<div>{{ item.mrchant_f }}人</div>
												</div>
											</div>
										</div>
									</div>
								</el-collapse>
							</div>
							<div class="orderImgBox">
								<div class="orderBox greenBackground">
									<img class="orderImg" src="../../assets/icon/line.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ activeVolume.all_active_volume }}</span> 人</div>
							<div class="d-flex">
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">用户量：{{ activeVolume.user_active_volume }}人</div>
								</div>
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">司机量：{{ activeVolume.driver_active_volume }}人</div>
								</div>
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">商家量：{{ activeVolume.mrchant_active_volume }}人</div>
								</div>
							</div>

						</el-card>
					</el-col>
					<!--今日交易额-->
					<el-col :md="8" :sm="12">
						<el-card class="box-card2">
							<div slot="header" class="clearfix">
								<span>{{ dataIndex == 1 ? '今日' : (dataIndex == 2 ? '昨日' : (dataIndex == 3 ? '本周' : (dataIndex == 4 ? '本月' : ''))) }}交易额</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox orangeBackground">
									<img class="orderImg" src="../../assets/icon/money.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ orderPrice.all_price }}</span>元 </div>
							<div class="d-flex">
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">出行交易额:{{ orderPrice.car_order_price }}元</div>
								</div>
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">商超交易额:{{ orderPrice.market_order_price }}元</div>
								</div>
								<div class="orderText">
									<div class="circle green"></div>
									<div class="">预约单交易金额:{{ orderPrice.taxi_hailing }}元</div>
								</div>
							</div>

						</el-card>
					</el-col>
				</el-row>
				<div id="main"></div>
				<el-row :gutter="15">
					<el-col :md="8" :sm="12">
						<el-card class="box-card1" style="height: 263px;">
							<div slot="header" class="clearfix">
								<span>累计用户量</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox redBackground">
									<img class="orderImg" src="../../assets/icon/user.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ allUserData.all_user }}</span> 个</div>
							<div style="display:flex;flex-wrap: wrap;justify-content: space-between;">
								<div class="orderText mr-20 mb-10">
									<span style="margin-left:14px">实际用户</span>
									<!-- <div class="progress" :style="{width:widthStyle}"></div> -->
									<div style="width: 80px;margin: 0 10px;">
										<el-progress :percentage="widthStyle" :color="customColor" :stroke-width="10"
											:show-text="false"></el-progress>
									</div>
									<span>{{ allUserData.real_user }}个</span>
								</div>
								<div class="orderText mr-20 mb-10">
									<span>未注册用户</span>
									<!-- <div class="progress" :style="{width:widthStyle1}"></div> -->
									<div style="width: 80px;margin: 0 10px;">
										<el-progress :percentage="widthStyle1" :color="customColor" :stroke-width="10"
											:show-text="false"></el-progress>
									</div>
									<span>{{ allUserData.not_certified_user }}个</span>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :md="8" :sm="12">
						<el-card class="box-card1">
							<div slot="header" class="clearfix">
								<span>累计商家量</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox blueBackground">
									<img class="orderImg" src="../../assets/icon/merchants.png" alt="" />
								</div>
							</div>

							<div class="sumNum"><span class="strong">{{ allMerchant.all_nums }}</span> 位</div>
							<div style="display:flex;flex-wrap: wrap;justify-content: space-between;">
								<div>
									<div class="orderText mr-10 mb-10">
										<div>司机</div>
										<!-- <div :style="{width:widthStyle2}" class="progress"></div> -->
										<div style="width: 50px;margin: 0 10px;">
											<el-progress :percentage="widthStyle2" :color="customColor" :stroke-width="10"
												:show-text="false"></el-progress>
										</div>
										<div>{{ allMerchant.all_driver }}位</div>
									</div>
									<div class="orderText mr-10 mb-10">
										<div>超市</div>
										<!-- <div :style="{width:widthStyle3}" class="progress"></div> -->
										<div style="width: 50px;margin: 0 10px;">
											<el-progress :percentage="widthStyle3" :color="customColor" :stroke-width="10"
												:show-text="false"></el-progress>
										</div>
										<div>{{ allMerchant.all_market }}位</div>
									</div>
								</div>
								<div>
									<div class="orderText mr-10 mb-10">
										<div>跑腿</div>
										<!-- <div :style="{width:widthStyle4}" class="progress"></div> -->
										<div style="width: 50px;margin: 0 10px;">
											<el-progress :percentage="widthStyle4" :color="customColor" :stroke-width="10"
												:show-text="false"></el-progress>
										</div>
										<div>{{ allMerchant.all_legwork }}位</div>
									</div>
									<div class="orderText mr-10 mb-10">
										<div>叫餐</div>
										<!-- <div :style="{width:widthStyle5}" class="progress"></div> -->
										<div style="width: 50px;margin: 0 10px;">
											<el-progress :percentage="widthStyle5" :color="customColor" :stroke-width="10"
												:show-text="false"></el-progress>
										</div>
										<div>{{ allMerchant.all_food }}位</div>
									</div>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :md="8" :sm="12">
						<el-card class="box-card1" style="height: 263px;">
							<div slot="header" class="clearfix">
								<span>平台每月收益</span>
							</div>
							<div class="orderImgBox">
								<div class="orderBox orangeBackground">
									<img class="orderImg" src="../../assets/icon/money.png" alt="" />
								</div>
							</div>
							<div class="sumNum"><span class="strong">{{ allMonthPrice.all_price }}</span>元</div>
							<div style="display:flex;flex-wrap: wrap;justify-content: space-between;">
								<div class="orderText  mr-20 mb-10">
									<div style="margin-left: 14px">出行收益</div>
									<!-- <div :style="{width:widthStyle6}" class="progress"></div> -->
									<div style="width: 80px;margin: 0 10px;">
										<el-progress :percentage="widthStyle6" :color="customColor" :stroke-width="10"
											:show-text="false"></el-progress>
									</div>
									<div>{{ allMonthPrice.car_order_price }}元</div>
								</div>
								<div class="orderText mr-20 mb-10">
									<div>考拉购收益</div>
									<!-- <div :style="{width:widthStyle7}" class="progress"></div> -->
									<div style="width: 80px;margin: 0 10px;">
										<el-progress :percentage="widthStyle7" :color="customColor" :stroke-width="10"
											:show-text="false"></el-progress>
									</div>
									<div>{{ allMonthPrice.market_order_price }}元</div>
								</div>
							</div>
						</el-card>
					</el-col>
				</el-row>
				<!-- 地图+闪电对话 -->
				<div class="d-flex" style="flex-wrap: nowrap;">
					<div id="map" style="width:700px;height:600px;background:#fff;padding-left: 2%"></div>
					<div class="charBox">
						<div class="m-15">
							<div class="mb-20">闪电对话</div>
							<template>
								<el-tabs v-model="activeName" type="card">
									<el-tab-pane name="first">
										<span slot="label" class="dialoTitle">
											<span class="span-box">
												<span>出租车</span>
											</span>
										</span>
										<div class="diaContent" style="height:450px;overflow:hidden">
											<div v-if="charList.length == 0" class="noOrder">当前暂无正在进行订单</div>
											<div v-else style="height:450px;overflow-y:auto;overflow-x:hidden">
												<div class="charRecord-inner" v-for="(option, index) in charList"
													:key="option.id">
													<!-- 时间 -->
													<div class="w-100"
														v-if="option.p_show == 1 && option.type !== 33 && option.type !== 34 && option.content.S != 89 && option.content.S != 38 && option.content.S != 94 && option.content.S != 27">
														<div class="charRecord-time">{{ option.create_time }}</div>
													</div>
													<!-- 系统消息-->
													<div class="w-100"
														v-if="(option.content.S == 5 || option.content.S == 100 || option.content.S == 1 || option.content.S == 20 || option.content.S == 39) && option.p_show == 1">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-titlesys">{{ option.content.M }}</div>
														</div>
													</div>
													<!-- 左侧 完善目的地-->
													<div class="w-100" v-if="option.content.S == 27 && option.direction == 3">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-title1 ">
																<div class="">
																	<div class="m-10 mb-0">您可以完善一下目的地</div>
																	<div class="whiteAddress">12</div>
																	<div class="addressBtn">
																		<span class="ignore">忽略</span>
																		<span class="submit">提交</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<!-- 右侧 完善目的地-->
													<div class="w-100" v-if="option.content.S == 27 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 ">
																<div class="">
																	<div class="m-10 mb-0">您可以完善一下目的地</div>
																	<div class="whiteAddress">请输入您要去的地址</div>
																	<div class="addressBtn">
																		<span class="ignore"
																			style="width: 100%;border-right: none">已忽略</span>
																	</div>
																</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-title2 p-10"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin@2x.png">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
															<div class="charRecord-title2 p-10" v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin.gif">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 bgGreen"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-ico.png">
																</div>

															</div>
															<div class="charRecord-title2 p-10 bgGreen"
																v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-gif.gif">
																</div>

															</div>
															<div class="rhh1"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 图片-->
													<div class="w-100" v-if="option.content.S == 22">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10"
																@click="hanlePicture(option.content.P.URL)">
																<img class="" :src="option.content.P.URL">
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">
																<img width='100px' height='100px' class="charHeadimg"
																	:src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 视频-->
													<div class="w-100" v-if="option.content.S == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10">
																<video width="100" height="100" controls>
																	<source :src="option.content.P.URL" type="video/mp4">
																</video>
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">

																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<!-- vid="amapDemo1" -->
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
														</div>
													</div>


													<!-- 左侧 语音通话 对方已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 3">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 4">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 未接听-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 5">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/call_ic_blackwait.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 6">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 3">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 4">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方无应答-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 5">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 订单账单-->
													<div class="w-100" v-if="option.content.S == 31">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png" />
															</div>
															<div class="hh"></div>
															<div class="charRecord-Paytitle">
																<div class="w-30">
																	<div class="m-10 mb-0 text-center">订单账单</div>
																	<div class="m-10 mb-0 text-center">
																		<span
																			class="price">{{ option.show_data.price }}</span>元
																	</div>
																	<div class="p-10 text-center payBox">
																		<div class="payTitle">行程车费</div>
																		<div class="payPrice">
																			{{ option.show_data.price }}元
																		</div>
																	</div>
																	<div class="p-10  payBox">
																		<div class="payTitle">零钱抵现</div>
																		<div class="payPrice"><span
																				style="color: #FF9D00;">-{{ option.show_data.bonus }}</span>元
																		</div>
																	</div>
																	<div class="p-10 payType">
																		<div>
																			<img src="../../assets/images/share_ic_wechat@2x.png"
																				style="float: left;" alt="" width="30px"
																				height="30px;">
																			<span class="wxPay">微信支付</span>
																		</div>

																		<img src="../../assets/images/ic_arrow@2x.png"
																			style="float: right;margin-top: 3px;" alt=""
																			width="10px" height="20px;">

																	</div>
																	<!--																	<div class="p-10 payType borderTop">-->
																	<!--																		<div>-->
																	<!--																			<img src="../../assets/images/ic_chat_pt_pay_alipay.png"-->
																	<!--																				style="float: left;" alt="" width="30px"-->
																	<!--																				height="30px;">-->
																	<!--																			<span class="wxPay">支付宝支付</span>-->
																	<!--																		</div>-->

																	<!--																		<img src="../../assets/images/ic_arrow@2x.png"-->
																	<!--																			style="float: right;margin-top: 3px;" alt=""-->
																	<!--																			width="10px" height="20px;">-->
																	<!--																	</div>-->
																	<div class="p-10 payType borderTop">
																		<div>
																			<img src="../../assets/images/ic_pay@2x.png"
																				style="float: left;" alt="" width="30px"
																				height="30px;">
																			<span class="wxPay">线下支付</span>
																		</div>

																		<img src="../../assets/images/ic_arrow@2x.png"
																			style="float: right;margin-top: 3px;" alt=""
																			width="10px" height="20px;">
																	</div>
																</div>
															</div>

														</div>
													</div>

												</div>
											</div>
										</div>
										<div v-if="charList.length !== 0" class="enterOrder" @click="handleNav(1)">
											进入当前对话</div>
									</el-tab-pane>
									<el-tab-pane name="second">
										<span slot="label" class="dialoTitle">
											<span class="span-box">
												<span>跑腿</span>
											</span>
										</span>
										<div class="diaContent" style="height:450px;overflow:hidden">
											<div v-if="charList1.length == 0" class="noOrder">当前暂无正在进行订单</div>
											<div v-else style="height:450px;overflow-y:auto;overflow-x:hidden">
												<div class="charRecord-inner" v-for="(option, index) in charList1"
													:key="option.id">
													<!-- 时间 -->
													<div class="w-100"
														v-if="option.p_show == 1 && option.type !== 33 && option.type !== 34">
														<div class="charRecord-time">{{ option.create_time }}</div>
													</div>
													<!-- 系统消息-->
													<div class="w-100"
														v-if="(option.content.S == 5 || option.content.S == 100 || option.content.S == 1) && option.p_show == 1">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-titlesys">{{ option.content.M }}</div>
														</div>
													</div>
													<!-- 左侧 完善目的地-->
													<div class="w-100" v-if="option.content.S == 27 && option.direction == 3">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-title1 ">
																<div class="">
																	<div class="m-10 mb-0">您可以完善一下目的地</div>
																	<div class="whiteAddress">12</div>
																	<div class="addressBtn">
																		<span class="ignore">忽略</span>
																		<span class="submit">提交</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-title2 p-10"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin@2x.png">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
															<div class="charRecord-title2 p-10" v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin.gif">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 bgGreen"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-ico.png">
																</div>

															</div>
															<div class="charRecord-title2 p-10 bgGreen"
																v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-gif.gif">
																</div>

															</div>
															<div class="rhh1"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 图片-->
													<div class="w-100" v-if="option.content.S == 22">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10"
																@click="hanlePicture(option.content.P.URL)">
																<img class="" :src="option.content.P.URL">
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">
																<img width='100px' height='100px' class="charHeadimg"
																	:src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 视频-->
													<div class="w-100" v-if="option.content.S == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10">
																<video width="100" height="100" controls>
																	<source :src="option.content.P.URL" type="video/mp4">
																</video>
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">

																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<!-- vid="amapDemo1" -->
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
														</div>
													</div>


													<!-- 左侧 语音通话 对方已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 3">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 4">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 未接听-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 5">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 6">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 3">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 4">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方无应答-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 5">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 订单账单-->
													<div class="w-100" v-if="option.content.S == 31">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png" />
															</div>
															<div class="hh"></div>
															<div class="charRecord-Paytitle">
																<div class="w-30">
																	<div class="m-10 mb-0 text-center">订单账单</div>
																	<div class="m-10 mb-0 text-center">
																		<span
																			class="price">{{ option.show_data.price }}</span>元
																	</div>
																	<div class="p-10 text-center payBox">
																		<div class="payTitle">行程车费</div>
																		<div class="payPrice">
																			{{ option.show_data.price }}元
																		</div>
																	</div>
																	<div class="p-10  payBox">
																		<div class="payTitle">零钱抵现</div>
																		<div class="payPrice"><span
																				style="color: #FF9D00;">-{{ option.show_data.bonus }}</span>元
																		</div>
																	</div>
																	<div class="p-10 payType">
																		<div>
																			<img src="../../assets/images/share_ic_wechat@2x.png"
																				style="float: left;" alt="" width="30px"
																				height="30px;">
																			<span class="wxPay">微信支付</span>
																		</div>

																		<img src="../../assets/images/ic_arrow@2x.png"
																			style="float: right;margin-top: 3px;" alt=""
																			width="10px" height="20px;">

																	</div>
																	<!--																	<div class="p-10 payType borderTop">-->
																	<!--																		<div>-->
																	<!--																			<img src="../../assets/images/ic_chat_pt_pay_alipay.png"-->
																	<!--																				style="float: left;" alt="" width="30px"-->
																	<!--																				height="30px;">-->
																	<!--																			<span class="wxPay">支付宝支付</span>-->
																	<!--																		</div>-->

																	<!--																		<img src="../../assets/images/ic_arrow@2x.png"-->
																	<!--																			style="float: right;margin-top: 3px;" alt=""-->
																	<!--																			width="10px" height="20px;">-->
																	<!--																	</div>-->
																</div>
															</div>

														</div>
													</div>

												</div>
											</div>
										</div>
										<div v-if="charList1.length !== 0" class="enterOrder" @click="handleNav(2)">
											进入当前对话</div>
									</el-tab-pane>
									<el-tab-pane name="third">
										<span slot="label" class="dialoTitle">
											<span class="span-box">
												<span>超市</span>
											</span>
										</span>
										<div class="diaContent" style="height:450px;overflow:hidden">
											<div v-if="charList2.length == 0" class="noOrder">当前暂无正在进行订单</div>
											<div v-else style="height:450px;overflow-y:auto;overflow-x:hidden">
												<div class="charRecord-inner" v-for="(option, index) in charList2"
													:key="option.id">
													<!-- 时间 -->
													<div class="w-100"
														v-if="option.p_show == 1 && option.type !== 33 && option.type !== 34">
														<div class="charRecord-time">{{ option.create_time }}</div>
													</div>
													<!-- 系统消息-->
													<div class="w-100"
														v-if="(option.content.S == 5 || option.content.S == 100 || option.content.S == 1) && option.p_show == 1">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-titlesys">{{ option.content.M }}</div>
														</div>
													</div>
													<!-- 左侧 完善目的地-->
													<div class="w-100" v-if="option.content.S == 27 && option.direction == 3">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-title1 ">
																<div class="">
																	<div class="m-10 mb-0">您可以完善一下目的地</div>
																	<div class="whiteAddress">12</div>
																	<div class="addressBtn">
																		<span class="ignore">忽略</span>
																		<span class="submit">提交</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-title2 p-10"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin@2x.png">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
															<div class="charRecord-title2 p-10" v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin.gif">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 bgGreen"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-ico.png">
																</div>

															</div>
															<div class="charRecord-title2 p-10 bgGreen"
																v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-gif.gif">
																</div>

															</div>
															<div class="rhh1"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 图片-->
													<div class="w-100" v-if="option.content.S == 22">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10"
																@click="hanlePicture(option.content.P.URL)">
																<img class="" :src="option.content.P.URL">
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">
																<img width='100px' height='100px' class="charHeadimg"
																	:src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 视频-->
													<div class="w-100" v-if="option.content.S == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10">
																<video width="100" height="100" controls>
																	<source :src="option.content.P.URL" type="video/mp4">
																</video>
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">

																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<!-- vid="amapDemo1" -->
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
														</div>
													</div>


													<!-- 左侧 语音通话 对方已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 3">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 4">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 未接听-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 5">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 6">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 3">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 4">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方无应答-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 5">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 订单账单-->
													<div class="w-100" v-if="option.content.S == 31">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png" />
															</div>
															<div class="hh"></div>
															<div class="charRecord-Paytitle">
																<div class="w-30">
																	<div class="m-10 mb-0 text-center">订单账单</div>
																	<div class="m-10 mb-0 text-center">
																		<span
																			class="price">{{ option.show_data.price }}</span>元
																	</div>
																	<div class="p-10 text-center payBox">
																		<div class="payTitle">行程车费</div>
																		<div class="payPrice">
																			{{ option.show_data.price }}元
																		</div>
																	</div>
																	<div class="p-10  payBox">
																		<div class="payTitle">零钱抵现</div>
																		<div class="payPrice"><span
																				style="color: #FF9D00;">-{{ option.show_data.bonus }}</span>元
																		</div>
																	</div>
																	<div class="p-10 payType">
																		<div>
																			<img src="../../assets/images/share_ic_wechat@2x.png"
																				style="float: left;" alt="" width="30px"
																				height="30px;">
																			<span class="wxPay">微信支付</span>
																		</div>

																		<img src="../../assets/images/ic_arrow@2x.png"
																			style="float: right;margin-top: 3px;" alt=""
																			width="10px" height="20px;">

																	</div>
																	<!--																	<div class="p-10 payType borderTop">-->
																	<!--																		<div>-->
																	<!--																			<img src="../../assets/images/ic_chat_pt_pay_alipay.png"-->
																	<!--																				style="float: left;" alt="" width="30px"-->
																	<!--																				height="30px;">-->
																	<!--																			<span class="wxPay">支付宝支付</span>-->
																	<!--																		</div>-->

																	<!--																		<img src="../../assets/images/ic_arrow@2x.png"-->
																	<!--																			style="float: right;margin-top: 3px;" alt=""-->
																	<!--																			width="10px" height="20px;">-->
																	<!--																	</div>-->
																</div>
															</div>

														</div>
													</div>

												</div>
											</div>
										</div>
										<div v-if="charList2.length !== 0" class="enterOrder" @click="handleNav(3)">
											进入当前对话</div>
									</el-tab-pane>
									<el-tab-pane name="fourth">
										<span slot="label" class="dialoTitle">
											<span class="span-box">
												<span>叫餐</span>
											</span>
										</span>
										<div class="diaContent" style="height:450px;overflow:hidden">
											<div v-if="charList3.length == 0" class="noOrder">当前暂无正在进行订单</div>
											<div v-else style="height:450px;overflow-y:auto;overflow-x:hidden">
												<div class="charRecord-inner" v-for="(option, index) in charList3"
													:key="option.id">
													<!-- 时间 -->
													<div class="w-100"
														v-if="option.p_show == 1 && option.type !== 33 && option.type !== 34">
														<div class="charRecord-time">{{ option.create_time }}</div>
													</div>
													<!-- 系统消息-->
													<div class="w-100"
														v-if="(option.content.S == 5 || option.content.S == 100 || option.content.S == 1) && option.p_show == 1">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-titlesys">{{ option.content.M }}</div>
														</div>
													</div>
													<!-- 左侧 完善目的地-->
													<div class="w-100" v-if="option.content.S == 27 && option.direction == 3">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png">
															</div>
															<div class="charRecord-title1 ">
																<div class="">
																	<div class="m-10 mb-0">您可以完善一下目的地</div>
																	<div class="whiteAddress">12</div>
																	<div class="addressBtn">
																		<span class="ignore">忽略</span>
																		<span class="submit">提交</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-title2 p-10"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin@2x.png">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
															<div class="charRecord-title2 p-10" v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div class="lvoide1">
																	<img src="../../assets/images/ic_b_yuyin.gif">
																</div>
																<div>{{ option.content.P.LONG }}''</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音-->
													<div class="w-100" v-if="option.content.S == 21 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 bgGreen"
																@click="handlePlay(option.content.P.URL, option.id, index)"
																v-if="option.isPlay == true">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-ico.png">
																</div>

															</div>
															<div class="charRecord-title2 p-10 bgGreen"
																v-if="option.isPlay == false">
																<audio class="pasuVideo" :src="option.content.P.URL"
																	:ref="'middle' + index">您的浏览器不支持</audio>
																<div style="color:#fff">{{ option.content.P.LONG }}''
																</div>
																<div class="rvoide1">
																	<img src="../../assets/images/yuyin-gif.gif">
																</div>

															</div>
															<div class="rhh1"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 图片-->
													<div class="w-100" v-if="option.content.S == 22">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10"
																@click="hanlePicture(option.content.P.URL)">
																<img class="" :src="option.content.P.URL">
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">
																<img width='100px' height='100px' class="charHeadimg"
																	:src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 视频-->
													<div class="w-100" v-if="option.content.S == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title1 p-10">
																<video width="100" height="100" controls>
																	<source :src="option.content.P.URL" type="video/mp4">
																</video>
															</div>
															<div class="rhh"></div>
															<div class="charRecord-image">

																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 1">
														<div class="charRecord-rightItem">
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<!-- vid="amapDemo1" -->
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 地图-->
													<div class="w-100" v-if="option.content.S == 26 && option.direction == 2">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>
															<div class="charRecord-titleMap p-10">
																<div class="amap-page-container">
																	<div :style="{ width: '100%', height: '180px' }">
																		<el-amap ref="map" :zoom="zoom"
																			:center="option.content.P.POINT">
																			<el-amap-marker></el-amap-marker>
																		</el-amap>
																	</div>
																</div>
															</div>
														</div>
													</div>


													<!-- 左侧 语音通话 对方已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 3">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 4">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 未接听-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 5">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
														</div>
													</div>
													<!-- 左侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 6">
														<div class="charRecord-leftItem">
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.driver.headimg">
															</div>
															<div class="hh"></div>

															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 已取消-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 3">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方已拒接-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 4">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 对方无应答-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 5">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phone3grey@2x.png">
																</div>
																<div>{{ option.content.P.voiceCallMessage }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 右侧 语音通话 通话时长-->
													<div class="w-100"
														v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 6">
														<div class="charRecord-rightItem">
															<div class="charRecord-title2 p-10 disCenter voide">
																<div class=" mr-10">
																	<img
																		src="../../assets/images/voice/ic_phonegrey@2x.png">
																</div>
																<div>{{ option.content.P.message }}</div>
															</div>
															<div class="rhh"></div>
															<div class="charRecordrightImage">
																<img class="charHeadimg" :src="option.user.headimg">
															</div>
														</div>
													</div>
													<!-- 左侧 订单账单-->
													<div class="w-100" v-if="option.content.S == 31">
														<div class="charRecord-leftItem">
															<div class="charRecord-image">
																<img class="charHeadimg" src="../system/order/logo1.png" />
															</div>
															<div class="hh"></div>
															<div class="charRecord-Paytitle">
																<div class="w-30">
																	<div class="m-10 mb-0 text-center">订单账单</div>
																	<div class="m-10 mb-0 text-center">
																		<span
																			class="price">{{ option.show_data.price }}</span>元
																	</div>
																	<div class="p-10 text-center payBox">
																		<div class="payTitle">行程车费</div>
																		<div class="payPrice">
																			{{ option.show_data.price }}元
																		</div>
																	</div>
																	<div class="p-10  payBox">
																		<div class="payTitle">零钱抵现</div>
																		<div class="payPrice"><span
																				style="color: #FF9D00;">-{{ option.show_data.bonus }}</span>元
																		</div>
																	</div>
																	<div class="p-10 payType">
																		<div>
																			<img src="../../assets/images/share_ic_wechat@2x.png"
																				style="float: left;" alt="" width="30px"
																				height="30px;">
																			<span class="wxPay">微信支付</span>
																		</div>

																		<img src="../../assets/images/ic_arrow@2x.png"
																			style="float: right;margin-top: 3px;" alt=""
																			width="10px" height="20px;">

																	</div>
																	<!--																	<div class="p-10 payType borderTop">-->
																	<!--																		<div>-->
																	<!--																			<img src="../../assets/images/ic_chat_pt_pay_alipay.png"-->
																	<!--																				style="float: left;" alt="" width="30px"-->
																	<!--																				height="30px;">-->
																	<!--																			<span class="wxPay">支付宝支付</span>-->
																	<!--																		</div>-->

																	<!--																		<img src="../../assets/images/ic_arrow@2x.png"-->
																	<!--																			style="float: right;margin-top: 3px;" alt=""-->
																	<!--																			width="10px" height="20px;">-->
																	<!--																	</div>-->
																</div>
															</div>

														</div>
													</div>

												</div>
											</div>
										</div>
										<div v-if="charList3.length !== 0" class="enterOrder" @click="handleNav(4)">
											进入当前对话</div>
									</el-tab-pane>
								</el-tabs>
							</template>
						</div>
					</div>
				</div>
			</div>
		</el-card>
		<!-- 查看出车记录 -->
		<el-dialog v-dialogDrag title="历史在线数据" :visible.sync="dialogTableVisibleOnline" class="records">
			<div id="onlineMian" style="width:100%;height:300px;"></div>
		</el-dialog>
		<!-- 司机定位 -->
		<el-dialog v-dialogDrag title="司机/跑腿当前位置" :visible.sync="dialogTableVisiblePositioning"
			@closed="[searchType = {}, markList = {}]" width='1000px'>
			<!-- <div class='d-flex' style="width:100%"> -->
			<!-- <div style="width:50%"> -->
			<el-card shadow="never">
				<el-form :model="searchType" label-width="77px" class="ele-form-search "
					style="display:flex;flex-wrap: wrap;" @keyup.enter.native="$refs.arrList.reload()"
					@submit.native.prevent>
					<el-form-item label="类型:" label-width="80px">
						<el-select v-model="searchType.type" placeholder="请选择" clearable class="ele-fluid">
							<el-option label="司机" value="1" />
							<el-option label="跑腿" value="4" />
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" style="border-radius: 5px;" @click="handleRef(searchType.type)"
							icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
						<el-button style="border-radius: 5px;" @click="(searchType = {}) && handleRef()">重置</el-button>
						<el-button type="danger" style="border-radius: 5px;" @click="ChangeMap()"
							class="ele-btn-icon ml-20">查看地图</el-button>
						<!-- :disabled="arrList==''?true:false" -->
						<!-- {{arrList}} -->
					</el-form-item>
				</el-form>
				<ele-data-table ref='arrList' :data='arrList' :choose.sync="choose" height="calc(100vh - 315px)"
					class="arrList" highlight-current-row :stripe=true>
					<template slot-scope="{index}">
						<el-table-column type="selection" width="45" align="center" fixed="left" />
						<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
							show-overflow-tooltip />
						<el-table-column prop="headimg" label="头像" show-overflow-tooltip min-width="80">
							<template slot-scope="{row}">
								<el-image style="width:25px;height:25px;" :src="row.headimg" fit="cover"
									:preview-src-list="[row.headimg]">
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="姓名" show-overflow-tooltip min-width="100" />
						<el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="200" />

						<el-table-column prop="vehicle_status" label="登录状态" show-overflow-tooltip min-width="100" />

						<el-table-column prop="driver_status" label="在线状态" show-overflow-tooltip min-width="100" />
						<el-table-column prop="type_name" label="类型" show-overflow-tooltip min-width="60">
						</el-table-column>
					</template>
				</ele-data-table>
			</el-card>
			<el-dialog v-dialogDrag title="司机定位地图" :visible.sync="VisibleChangeMap" append-to-body width="50em">
				<div :style="{ width: '50em', height: '700px' }">
					<el-amap ref="map" vid="amapDemo" :zoom="zoom" :center="center">
						<el-amap-marker v-for="(marker, index) in markList" :key="index" :title="marker.title"
							:label="marker.label" :icon="marker.icon" :position='marker.position'>
						</el-amap-marker>
					</el-amap>
				</div>
			</el-dialog>
		</el-dialog>

	</div>
</template>

<script src="https://webapi.amap.com/maps?v=1.4.13&key=645cd97a39f5c47bafce4a316785f342"></script>
<script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script>

<script>
	import {
		load
	} from '@amap/amap-jsapi-loader'

	export default {

		name: "SysUser",
		data() {
			return {
				isActive: false,
				isSevent: false,
				widthStyle: 0,
				widthStyle1: 0,
				widthStyle2: 0,
				widthStyle3: 0,
				widthStyle4: 0,
				widthStyle5: 0,
				widthStyle6: 0,
				widthStyle7: 0,
				areaData: [],
				activeData: [],
				arrList: [],
				table: {
					url: '/datas/get_data_show',
					where: {}
				},
				param1: {},
				param: {
					pid: '',
					cid: '',
					aid: '',
					create_time: '',
					end_time: '',
					data: '今日',
					yesterday: '昨日',
					week: '本周',
					month: '本月',
					all_active_volume: ''
				},
				currIndex: 0,
				zoom: 20,
				// center: [116.352963,40.409079], // 北京
				center:[],
				// center: [118.358868, 35.050675],
				markList: [],
				dataIndex: 1,
				dialogTableVisibleOnline: false,
				VisibleChangeMap:false,
				activeName: 'first',
				table: {
					url: '/datas/map_data',
					where: {
						pid: '',
						cid: '',
						aid: '',
						start_time: '',
						end_time: '',
					}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				editRules: { // 表单验证规则                      
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					},{
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, 
						message: '请输入正确的手机号', 
						trigger: 'blur',
					}],
					name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请输入性别，1男2女',
						trigger: 'blur'
					}],
					home: [{
						required: true,
						message: '请输入家乡名称，对应aid',
						trigger: 'blur'
					}],

					money: [{
						required: true,
						message: '请输入余额',
						trigger: 'blur'
					}],

					losemoney: [{
						required: true,
						message: '请输入已提现/使用余额',
						trigger: 'blur'
					}],

				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleEdit: false,
				dialogTableVisibleUrgent: false,
				dialogTableVisiblePositioning: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				list: {
					polyline_data: {}
				},
				orderData: {},
				userData: {},
				merchantData: {},
				driverData: {},
				orderPrice: {},
				activeVolume: {},
				allUserData: {},
				allMerchant: {},
				allMonthPrice: {},
				mapData: [],
				taxiDate: [],
				charList: [],
				charList1: [],
				charList2: [],
				charList3: [],
				linshiList: [],
				timeSearch: [],
				dataList: [],
				searchType:[],
				customColor:'#33CC99'
			}
		},

		created() {
			this.param1.aid = '请选择地区'
			const loading = this.$loading({
				lock: true,
				target: document.querySelector(".activeData")
			});
			this.$http.get('/datas/get_area_online_data').then(res => {
				loading.close()
				this.areaData = res.data.area_data
				this.activeData = res.data.active_data
				this.linshiList = res.data.active_data
				this.activeData.forEach(function(item) {
					let sum = parseInt(item.driver_active_volume) + parseInt(item.legwork_active_volume) +
						parseInt(item.mrchant_active_volume) +
						parseInt(item.mrchant_f) + parseInt(item.mrchant_m) + parseInt(item
							.user_active_volume)

					if (item.user_active_volume != 0) {
						item.seventWidth1 = (item.user_active_volume / sum * 200) + 'px'
					} else {
						item.seventWidth1 = '10px'
					}

					if (item.driver_active_volume != 0) {
						if (item.driver_active_volume == 1) {
							item.seventWidth2 = (item.driver_active_volume / sum * 30) + 'px'
						}else{
							item.seventWidth2 = (item.driver_active_volume / sum * 200) + 'px'
						}
				
					} else {
						item.seventWidth2 = '10px'
					}

					if (item.legwork_active_volume != 0) {
						item.seventWidth3 = (item.legwork_active_volume / sum * 200) + 'px'
					} else {
						item.seventWidth3 = '10px'
					}

					if (item.mrchant_active_volume != 0) {
						item.seventWidth3 = (item.mrchant_active_volume / sum * 200) + 'px'
					} else {
						item.seventWidth3 = '10px'
					}

					if (item.mrchant_m != 0) {
						item.seventWidth4 = (item.mrchant_m / sum * 200) + 'px'
					} else {
						item.seventWidth4 = '10px'
					}

					if (item.mrchant_f != 0) {
						item.seventWidth5 = (item.mrchant_f / sum * 200) + 'px'
					} else {
						item.seventWidth5 = '10px'
					}
				})

			})

			this.setEchartOption();
			this.$http.get('/datas/get_order_push_log').then(res => {
				// 出租车
				res.data.taxi_date.forEach(function(item) {
					item.content = JSON.parse(item.content)
					if (item.content.S == 21) {
						item.isPlay = true
					}
					item.isTimeShow = 1
					if (item.content.S == 26) {
						item.content.P.POINT = item.content.P.POINT.split(',')
					}
				})
				res.data.taxi_date.reverse();
				for (var i = 0; i < res.data.taxi_date.length; i++) {
					for (var j = i + 1; j < res.data.taxi_date.length - 1; j++) {
						if (res.data.taxi_date[i].create_time == res.data.taxi_date[j].create_time) {
							res.data.taxi_date[j].isTimeShow = 0 //不显示时间
						} else {
							res.data.taxi_date[j].isTimeShow = 1
						}
					}
				}
				res.data.taxi_date.forEach(function(item) {
					var now = new Date(item.create_time * 1000);
					var year = now.getFullYear();
					var month = now.getMonth() + 1;
					var date = now.getDate();
					var hour = now.getHours();
					var minute = now.getMinutes();
					month = month < 10 ? '0' + month : month
					date = date < 10 ? '0' + date : date
					hour = hour < 10 ? '0' + hour : hour
					minute = minute < 10 ? '0' + minute : minute
					item.create_time = year + '-' + month + '-' + date + ' ' + hour + ':' + minute
				})
				this.charList = [];
				// this.charList = res.data.taxi_date
				// 跑腿
				res.data.legwork_date.forEach(function(item) {
					item.content = JSON.parse(item.content)
					if (item.content.S == 21) {
						item.isPlay = true
					}
					item.isTimeShow = 1
					if (item.content.S == 26) {
						item.content.P.POINT = item.content.P.POINT.split(',')
					}
				})
				res.data.legwork_date.reverse();
				for (var i = 0; i < res.data.legwork_date.length; i++) {
					for (var j = i + 1; j < res.data.legwork_date.length - 1; j++) {
						if (res.data.legwork_date[i].create_time == res.data.legwork_date[j].create_time) {
							res.data.legwork_date[j].isTimeShow = 0 //不显示时间
						} else {
							res.data.legwork_date[j].isTimeShow = 1
						}
					}
				}
				res.data.legwork_date.forEach(function(item) {
					var now = new Date(item.create_time * 1000);
					var year = now.getFullYear();
					var month = now.getMonth() + 1;
					var date = now.getDate();
					var hour = now.getHours();
					var minute = now.getMinutes();
					month = month < 10 ? '0' + month : month
					date = date < 10 ? '0' + date : date
					hour = hour < 10 ? '0' + hour : hour
					minute = minute < 10 ? '0' + minute : minute
					item.create_time = year + '-' + month + '-' + date + ' ' + hour + ':' + minute
				})
				this.charList1 = []
				// this.charList1 = res.data.legwork_date
			// 超市
				res.data.market_date.forEach(function(item) {
					item.content = JSON.parse(item.content)
					if (item.content.S == 21) {
						item.isPlay = true
					}
					item.isTimeShow = 1
					if (item.content.S == 26) {
						item.content.P.POINT = item.content.P.POINT.split(',')
					}
				})
				res.data.market_date.reverse();
				for (var i = 0; i < res.data.market_date.length; i++) {
					for (var j = i + 1; j < res.data.market_date.length - 1; j++) {
						if (res.data.market_date[i].create_time == res.data.market_date[j].create_time) {
							res.data.market_date[j].isTimeShow = 0 //不显示时间
						} else {
							res.data.market_date[j].isTimeShow = 1
						}
					}
				}
				res.data.market_date.forEach(function(item) {
					var now = new Date(item.create_time * 1000);
					var year = now.getFullYear();
					var month = now.getMonth() + 1;
					var date = now.getDate();
					var hour = now.getHours();
					var minute = now.getMinutes();
					month = month < 10 ? '0' + month : month
					date = date < 10 ? '0' + date : date
					hour = hour < 10 ? '0' + hour : hour
					minute = minute < 10 ? '0' + minute : minute
					item.create_time = year + '-' + month + '-' + date + ' ' + hour + ':' + minute
				})
				this.charList2 = []
				// this.charList2 = res.data.market_date
			// 叫餐
				res.data.food_date.forEach(function(item) {
					item.content = JSON.parse(item.content)
					if (item.content.S == 21) {
						item.isPlay = true
					}
					item.isTimeShow = 1
					if (item.content.S == 26) {
						item.content.P.POINT = item.content.P.POINT.split(',')
					}
				})
				res.data.food_date.reverse();
				for (var i = 0; i < res.data.food_date.length; i++) {
					for (var j = i + 1; j < res.data.food_date.length - 1; j++) {
						if (res.data.food_date[i].create_time == res.data.food_date[j].create_time) {
							res.data.food_date[j].isTimeShow = 0 //不显示时间
						} else {
							res.data.food_date[j].isTimeShow = 1
						}
					}
				}
				res.data.food_date.forEach(function(item) {
					var now = new Date(item.create_time * 1000);
					var year = now.getFullYear();
					var month = now.getMonth() + 1;
					var date = now.getDate();
					var hour = now.getHours();
					var minute = now.getMinutes();
					month = month < 10 ? '0' + month : month
					date = date < 10 ? '0' + date : date
					hour = hour < 10 ? '0' + hour : hour
					minute = minute < 10 ? '0' + minute : minute
					item.create_time = year + '-' + month + '-' + date + ' ' + hour + ':' + minute
				})
				this.charList3 = []
				// this.charList3 = res.data.food_date
			})
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
			// const loading = this.$loading({lock: true});
			this.$http.post('/datas/index', this.param).then(res => {
				// loading.close()
				this.list = res.data
				this.orderData = res.data.order_data
				this.userData = res.data.user_data
				this.merchantData = res.data.merchant_data
				this.driverData = res.data.driver_data
				this.orderPrice = res.data.order_price
				this.activeVolume = res.data.active_volume
				this.allUserData = res.data.all_user_data
				this.allMerchant = res.data.all_merchant
				this.allMonthPrice = res.data.all_month_price
				this.mapData = res.data.map_data
				// this.widthStyle = (this.allUserData.real_user / this.allUserData.all_user * 10) + 'rem'
				// this.widthStyle1 = (this.allUserData.not_certified_user / this.allUserData.all_user * 10) + 'rem'
				this.widthStyle = Math.round(this.allUserData.real_user/(this.allUserData.real_user + this.allUserData.not_certified_user)*100)
				this.widthStyle1 = Math.round(this.allUserData.not_certified_user/(this.allUserData.real_user + this.allUserData.not_certified_user)*100)

				if (this.allMerchant.all_driver != 0) {
					// this.widthStyle2 = (this.allMerchant.all_driver / this.allMerchant.all_nums * 10) + 'rem'
					this.widthStyle2 = Math.round(this.allMerchant.all_driver / this.allMerchant.all_nums * 10)
				} else {
					this.widthStyle2 = 0
					// this.widthStyle2 = '2rem'
				}

				if (this.allMerchant.all_market != 0) {
					this.widthStyle3 = Math.round(this.allMerchant.all_market / this.allMerchant.all_nums * 100)
				} else {
					this.widthStyle3 = 0
					// this.widthStyle3 = '2rem'
				}

				if (this.allMerchant.all_legwork != 0) {
					this.widthStyle4 = Math.round(this.allMerchant.all_legwork / this.allMerchant.all_nums * 100)
				} else {
					this.widthStyle4 = 0
					// this.widthStyle4 = '2rem'
				}

				if (this.allMerchant.all_food != 0) {
					this.widthStyle5 = Math.round(this.allMerchant.all_food / this.allMerchant.all_nums * 100)
					// this.widthStyle5 = (this.allMerchant.all_food / this.allMerchant.all_nums * 10) + 'rem'
				} else {
					this.widthStyle5 = 0
					// this.widthStyle5 = '2rem'
				}

				if (this.allMonthPrice.car_order_price != 0) {
					this.widthStyle6 = Math.round(this.allMonthPrice.car_order_price / this.allMonthPrice.all_price * 100)
				} else {
					this.widthStyle6 = 0
					// this.widthStyle6 = '2rem'
				}

				if (this.allMonthPrice.market_order_price != 0) {
					this.widthStyle7 = (this.allMonthPrice.market_order_price / this.allMonthPrice.all_price * 100)
				} else {
					this.widthStyle7 = 0
					// this.widthStyle7 = '2rem'
				}
				this.$nextTick(() => {
					let dom = document.getElementById('main')
					let myChart = this.$echarts.init(dom);
					let option;
					option = {
						title: {
							text: '统计数据',
							textStyle: {
								fontSize: 14
							}
						},
						legend: {
							left: 'center',
							padding: [30, 0, 30, 0],
							data: ['95128电召订单数','巡游出租订单数',
							'预约单订单数',
							'当日订单数', '用户新增数', '司机新增数', '超市新增数', '餐饮新增数', '跑腿新增数', '生活新增数']
						},
						tooltip: {
							trigger: 'axis',
						},
						xAxis: {
							type: 'category',
							data: res.data.polyline_data.date.split(',')
						},
						yAxis: [{

							min: 0,
							max: 2100,
							interval: 300
						}],
						grid: {
							left: '5%',
							right: '4%',
							bottom: '3%',
							top: '20%',
							containLabel: true
						},
						series: [
							// {
							// 	name: '当日订单数',
							// 	type: 'line',
							// 	data: res.data.polyline_data.num1.split(',')

							// },
							{
								name: '用户新增数',
								type: 'line',
								data: res.data.polyline_data.num2.split(',')
							},
							{
								name: '司机新增数',
								type: 'line',
								data: res.data.polyline_data.num3.split(',')
							},
							{
								name: '超市新增数',
								type: 'line',
								data: res.data.polyline_data.num4.split(',')
							},
							{
								name: '餐饮新增数',
								type: 'line',
								data: res.data.polyline_data.num5.split(',')
							},
							{
								name: '跑腿新增数',
								type: 'line',
								data: res.data.polyline_data.num6.split(',')
							},
							{
								name: '95128电召订单数',
								type: 'line',
								data: res.data.polyline_data.num8.split(',')
							},
							{
								name: '巡游出租订单数',
								type: 'line',
								data: res.data.polyline_data.num9.split(',')
							},
							// {
							// 	name: '预约单订单数',
							// 	type: 'line',
							// 	data: res.data.polyline_data.num10.split(',')
							// },
							{
								name: '预约单订单数',
								type: 'line',
								data: res.data.polyline_data.nums.split(',')
							},
						],
						toolbox: {
							show: true,
							feature: {
								saveAsImage: { //保存图片
									show: true,

								},

							}
						},
					};
					option && myChart.setOption(option);

				})
			})
		},
		methods: {
			handleChange11() {
				this.param1.aid = '请选择地区'
				this.isActive = true
				this.isSevent = true
				this.activeData = this.linshiList
			},
			handleZhankai() {
			},
			handleClose() {
				this.isActive = false
				this.isSevent = false
			},
			handleSelect(e) {
				var that = this
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".activeData")
				});
				this.activeData = []
				this.linshiList.forEach(function(item) {
					if (item.id == e) {
						that.activeData.push(item)
						loading.close()
					}
				})
			},
			//初始化中国地图
			initEchartMap() {
				// let mapDiv = document.getElementById('map');
				// let myChart = this.$echarts.init(mapDiv);
				// myChart.setOption(this.options);
			},

			//修改echart配制
			setEchartOption() {
				this.$http.get('/datas/map_data').then(res => {
					//console.log(res)
					var mapDiv = document.getElementById('map');
					var myChart = this.$echarts.init(mapDiv);
					
					var option = {
						tooltip: {
							formatter: function(params, ticket, callback) {
								return '省份：' + params.data.name + '<br />' + '订单数：' + params.data
									.order_num + '<br />' + '用户入驻数：' + params.data.user_num + '<br />' +
									'商户入驻数：' + params.data.driver_num + '<br />' + '开通的县城数：' + params.data
									.value
							}
						},
						visualMap: {
							min: 0,
							max: 10,
							left: 'left',
							top: 'bottom',
							text: ['高', '低'],
							inRange: {
								color: ['#e0ffff', '#006edd']
							},
							show: true
						},
						geo: {
							map: 'china',
							roam: false,
							zoom: 1.23,
							label: {
								normal: {
									show: true,
									fontSize: '10',
									color: 'rgba(0,0,0,0.7)'
								}
							},
							itemStyle: {
								normal: {
									borderColor: 'rgba(0, 0, 0, 0.2)'
								},
								emphasis: {
									areaColor: '#33CC99',
									shadowOffsetX: 0,
									shadowOffsetY: 0,
									shadowBlur: 20,
									borderWidth: 0,
									shadowColor: 'rgba(0, 0, 0, 0.3)'
								}
							}
						},
						series: [{
							name: '信息量',
							type: 'map',
							geoIndex: 0,
							data: res.data
						}]
					};
					myChart.setOption(option);
				})
			},
			//刷新
			handleRef(type) {
				// if(this.arrList == ''){
				// 	//console.log("无数据")
				// }else{
					const loading = this.$loading({
						lock: true,
						target: document.querySelector(".arrList")
					})
					let List = {}
					List.pid = this.table.where.pid
					List.cid = this.table.where.cid
					List.aid = this.table.where.aid
					this.$http.post('/datas/get_driver_list',{type:type,pid:List.pid,cid:List.cid,aid:List.aid}).then(res => {
						this.arrList = res.data.data
						loading.close()
						this.$refs.arrList.reload();
					})
				// }
			},
				/*** 获取时间搜索*/
				getTimeSearch() {
					// 开始时间 结束时间选择后
					if(this.table.where.start_time !==''){
						this.table.where.data_type = '';
						this.dataIndex = '';
					}
					if(this.table.where.start_time>this.table.where.end_time){
						this.$message.error("开始时间不得大于结束时间");
					}else{
            //console.log('555555555555555555555',this.table.where);
						const loading = this.$loading({lock: true,target: document.querySelector(".dataTable")});
						this.$http.post('/datas/get_data_show', this.table.where).then(res => {
							loading.close()
							if (res.data.code === 0) {
								this.activeData = res.data.data.active_data;
								this.driverData = res.data.data.driver_data;
								this.merchantData = res.data.data.merchant_data;
								this.orderData = res.data.data.order_data;
								this.orderPrice = res.data.data.order_price;
								this.userData = res.data.data.user_data;
								this.allUserData = res.data.data.all_user_data;
								this.allMerchant = res.data.data.all_merchant;
                this.allMonthPrice = res.data.data.all_month_price;
                this.activeVolume = res.data.data.active_volume;

                if (this.allMerchant.all_driver != 0) {
                  // this.widthStyle2 = (this.allMerchant.all_driver / this.allMerchant.all_nums * 10) + 'rem'
                  this.widthStyle2 = Math.round(this.allMerchant.all_driver / this.allMerchant.all_nums * 10)
                } else {
                  this.widthStyle2 = 0
                  // this.widthStyle2 = '2rem'
                }

                if (this.allMerchant.all_market != 0) {
                  this.widthStyle3 = Math.round(this.allMerchant.all_market / this.allMerchant.all_nums * 100)
                } else {
                  this.widthStyle3 = 0
                  // this.widthStyle3 = '2rem'
                }

                if (this.allMerchant.all_legwork != 0) {
                  this.widthStyle4 = Math.round(this.allMerchant.all_legwork / this.allMerchant.all_nums * 100)
                } else {
                  this.widthStyle4 = 0
                  // this.widthStyle4 = '2rem'
                }

                if (this.allMerchant.all_food != 0) {
                  this.widthStyle5 = Math.round(this.allMerchant.all_food / this.allMerchant.all_nums * 100)
                  // this.widthStyle5 = (this.allMerchant.all_food / this.allMerchant.all_nums * 10) + 'rem'
                } else {
                  this.widthStyle5 = 0
                  // this.widthStyle5 = '2rem'
                }

                if (this.allMonthPrice.car_order_price != 0) {
                  this.widthStyle6 = Math.round(this.allMonthPrice.car_order_price / this.allMonthPrice.all_price * 100)
                } else {
                  this.widthStyle6 = 0
                  // this.widthStyle6 = '2rem'
                }

                if (this.allMonthPrice.market_order_price != 0) {
                  this.widthStyle7 = (this.allMonthPrice.market_order_price / this.allMonthPrice.all_price * 100)
                } else {
                  this.widthStyle7 = 0
                  // this.widthStyle7 = '2rem'
                }
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}
				},
			handledate(e) {
				if(e == 1 || e == 2 || e == 3 || e == 4){
						this.table.where.start_time = '';
						this.table.where.end_time = '';
				}
				this.dataIndex = e
				this.$http.get('/datas/get_driver_list').then(res => {
					this.arrList = res.data.data
				})
				this.table.where.data_type = e
				this.getTimeSearch()
			},
			ChangeMap(){
				// const loading = this.$loading({lock: true, target: document.querySelector(".amapLoad")});
				if(this.arrList == ''){
					this.$message.error("暂无数据");
				}else{
					// loading.close();
          //console.log('ssss',this.markList)
					this.VisibleChangeMap = true;
				}
			},
			handleChangePositioning() {
				// 青岛
				// this.center = [116.188106,39.727064]
				if (this.table.where.pid && this.table.where.cid && this.table.where.aid) {
					this.dialogTableVisiblePositioning = true
					let List = {}
					List.pid = this.table.where.pid
					List.cid = this.table.where.cid
					List.aid = this.table.where.aid
					this.$http.post('/datas/get_driver_list',{pid:List.pid,cid:List.cid,aid:List.aid}).then(res => {
						this.arrList = res.data.data
						let markers = [];
						//console.log(this.arrList);
						for (let i = 0; i < this.arrList.length; i++) {
							if(this.arrList[i].type==1){
								// 1 载客 0空闲
								if(this.arrList[i].is_carry==1){
									markers.push({
										position: [parseFloat(this.arrList[i].lng), parseFloat(this.arrList[i].lat)],
										icon: require("@/assets/home_img_carrypassenger@2x.png"),
										// icon: 'home_img_carrypassenger@2x.png',
										title: '姓名:'+this.arrList[i].name+' '+'手机号:'+this.arrList[i].phone+' '+'载客状态:'+this.arrList[i].driver_status+' 车牌号:'+this.arrList[i].car_number,
                    label: {
                      offset: [40,10],
                      content: '',
                      direction: 'right'
                    },
									});
								}else{
									markers.push({
										position: [parseFloat(this.arrList[i].lng), parseFloat(this.arrList[i].lat)],
										icon: require("@/assets/home_img_emptycar@2x.png"),
										title: '姓名:'+this.arrList[i].name+' '+'手机号:'+this.arrList[i].phone+' '+'载客状态:'+this.arrList[i].driver_status+' 车牌号:'+this.arrList[i].car_number,
                    label: {
                      offset: [40,10],
                      content: '',
                      direction: 'right'
                    },
									});
								}
							}else{
								markers.push({
										position: [parseFloat(this.arrList[i].lng), parseFloat(this.arrList[i].lat)],
									});
							}
							
						}
						var center = []
            if (markers[0]) {
              if(markers[0].position[0] && markers[0].position[1]){
                center[0] = markers[0].position[0]
                center[1] = markers[0].position[1]
                this.center = center;
                // Object.assign(this.center,center)
              }
            }
						//console.log(center)
            //console.log(markers)
            //console.log('20220704')
            this.markList = markers
						// Object.assign(this.markList,markers)
					})
				} else {
					this.$message.error('请选择省市区');
				}
			},
			handleChangeOnline() {
				if (this.table.where.pid && this.table.where.cid && this.table.where.aid) {
					this.dialogTableVisibleOnline = true
					const loading = this.$loading({
						lock: true,
						target: document.querySelector(".records")
					});
					this.$http.post('/datas/get_driver_online_log', {
						pid: this.table.where.pid,
						cid: this.table.where.cid,
						aid: this.table.where.aid
					}).then(res => {
						loading.close()
						this.$nextTick(() => {
							let dom = document.getElementById('onlineMian')
							let myChart = this.$echarts.init(dom);
							let option;
							option = {
								tooltip: {
									trigger: 'axis',
								},
								legend: {
									left: 'center',
									data: ['司机历史在线数量', '跑腿历史在线数量', '超市历史在线数量', '叫餐历史在线数量']
								},
								xAxis: {
									type: 'category',
									data: res.data.dates.split(',')
								},
								yAxis: [{
									min: 0,
									// max: 250,
                  max: function (value) {
                    return Math.ceil(value.max);
                  },
									interval: 50
								}],
								series: [{
										name: '司机历史在线数量',
										type: 'line',
										data: res.data.driver_nums.split(',')
									},
									{
										name: '跑腿历史在线数量',
										type: 'line',
										data: res.data.legwork_nums.split(',')
									},
									{
										name: '超市历史在线数量',
										type: 'line',
										data: res.data.market_nums.split(',')
									},
									{
										name: '叫餐历史在线数量',
										type: 'line',
										data: res.data.food_nums.split(',')
									},
								],
								toolbox: {
									show: true,
									feature: {
										saveAsImage: { //保存图片
											show: true,
										},
									}
								},
							};
							option && myChart.setOption(option);
						})
					})
				} else {
					this.$message.error('请选择省市区');
				}
			},
			audioPlay() {
				//var mp3 = "https://zszx.zdache.cn:8441/dingdong.aac";
				// var mp3 = new Audio(mp3);
				// mp3.play(); 
			},
			handleNav(type) {
				if(type == 1){
					var orderId = this.charList[0].order_id
					this.$router.push({
						path: `/system/order?orderId=${orderId}`
					})
				}else if(type == 2){
					var orderId = this.charList1[0].order_id
					this.$router.push({
						path: `/system/order/shop_list?orderId=${orderId}`
					})
				}else if(type == 3){
					var orderId = this.charList2[0].order_id
					this.$router.push({
						path: `/system/order/merchant?orderId=${orderId}`
					})
				}else if(type == 4){
					var orderId = this.charList3[0].order_id
					this.$router.push({
						path: `/system/order/merchant?orderId=${orderId}`
					})
				}
			},
			dropClick(command, row) {
				if (command === 'handlecheck') { // 查看用户信息
					this.handlecheck()
				} else if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'handleUrgent') { //紧急联系人
					this.handleUrgent(row)
				} else if (command === 'handleEdit') { //修改用户信息
					this.handleEdit(row)
				} else if (command === 'blacklist') {
					this.blacklist(row)

				}
			},
			//语音播放
			handlePlay(url, id, index) {
				let that = this
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (var i = 0; i < middlePlay.length; i++) {
					middlePlay[i].pause()
					middlePlay[i].currentTime = 0
				}
				var middle = 'middle' + index
				var middlePlay = this.$refs[middle][0]
				middlePlay.play()
				for (var i = 0; i < this.charList.length; i++) {
					if (i == index) {
						this.charList[index].isPlay = false
						middlePlay.addEventListener('ended', function() {
							that.charList[index].isPlay = true
						})
					} else {
						that.charList[i].isPlay = true
					}
				}

				//  let that=this
				// this.audio = new Audio(url);
				// this.charList.forEach(item => {
				//   if(item.id==id){
				//     item.isPlay=false
				//     // that.audio.play();
				//     that.audio.addEventListener('ended', function () {
				//       item.isPlay=true
				//     }, false);
				//   }else{
				//     item.isPlay=true
				//   }

				// });

			},

			/***选择省**/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/***选择市**/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},


			/***修改省**/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				let that = this
				this.changePro = false
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					that.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/***修改市**/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = false
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},

		},
		mounted() {
			this.$nextTick(() => {
				this.initEchartMap();
			})

		}
	}
</script>


<style scoped>
/* 图标大小修改 */
/deep/ .amap-icon img {
	max-width: 80px !important;
	max-height: 80px !important;
}

button {
	border-radius: 0;
}

button::after {
	border-radius: 0;
}

/deep/ .el-input--medium .el-input__inner {
	line-height: 28px !important;
}

.btn-default {
	background-color: #c2c2c2;
	border-color: #c2c2c2;
	color: #FFF;
	padding: 10px 12px;
}

.el-checkbox {
	padding: 10px 20px !important;
}

.el-dropdown-menu--medium {
	width: 200px !important
}

/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
	border: 1px solid transparent !important
}

.ele-block .el-upload-dragger {
	width: 100%;
}

.changeStyle {
	width: 150px;
	margin-right: 30px;
}

#main {
	height: 400px;
	background: #fff;
	margin-bottom: 20px;
	padding: 20px 13px;
}

.charBox {
	background: #fff;
	width: 450px;
	height: 600px;
}

.el-tabs__item.is-top.is-active {
	color: #33CC99 !important
}

/* .el-card{background:#EDEDED !important} */
.el-upload-list el-upload-list--text {
	display: none !important
}

.el-tabs--card>.el-tabs__header /deep/ .el-tabs__nav {
	border: 1px solid red !important
}

.orderTitle {
	font-size: 16px;
}

.orderSum {
	display: flex;
	justify-content: center;
}

.orderImgBox {
	align-items: center;
	display: flex;
	justify-content: center;
}

.orderBox {
	padding: 12px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.greenBackground {
	background: #D6F5EB;
}

.orangeBackground {
	background: #FEEACD;
}

.redBackground {
	background: #FFDCCC;
}

.blueBackground {
	background: #CCEFFF;
}

.orderImg {
	width: 30px
}

.d-flex {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap
}

.sumNum {
	padding: 10px 0 30px;
	text-align: center
}

.todayColor {
	padding: 4px 8px;
	background: #ccc;
	border-radius: 4px;
}

.orderDetail {
	display: flex;
	justify-content: space-between;
}

.circle {
	width: 13px;
	height: 13px;
	border-radius: 50%;
	margin-right: 4px
}

.green {
	background: #33CC99;
}

.red {
	background: #FF4E00;
}

.gray {
	background: #979797;
}

.orderText {
	display: flex;
	align-items: center;
}

.strong {
	font-size: 28px;
	font-weight: 700;
	color: #000
}

.el-card__header {
	border-bottom: #fff !important;
}

.el-card.box-card.is-always-shadow {
	background: #fff !important
}

.box-card1 {
	background: #fff !important;
	margin-bottom: 10px;
}

.box-card2 {
	background: #fff !important;
	margin-bottom: 10px;
	height: 240px
}

.progress {
	height: 10px;
	background: #33CC99;
	margin: 0 10px;
}

.el-tabs__content {
	background: #ECECEC !important
}

.diaTitle {
	text-align: center;
	padding: 10px 0;
}

.diaContent {
	background: #ECECEC
}

.el-form.ele-form-search {
	margin-bottom: 10px !important;
	background: #fff !important;
	padding: 0 5px
}

.dayBox {
	display: flex;
	padding-top: 6px;
	cursor: pointer
}

.el-card__body {
	padding: 0 !important;
	height: 180px !important
}

.el-form-item__content {
	margin-left: 40px !important
}

.ele-form-search .el-form-item,
.ele-form-search .ele-form-actions {
	margin-bottom: 0
}

.el-form-item.el-form-item--medium .el-form-item__label {
	width: 40px !important
}

.el-card /deep/ .el-card__header {
	border: none;
	padding: 13px
}

.greenActive {
	color: #36BCA0;
	border-bottom: 2px solid #36BCA0;
	height: 20px;
	margin: 0 10px
}

.dayStyle {
	height: 20px;
	margin: 0 10px
}

.seventActivity {
	width: 400px;
	height: 572px;
	overflow: auto;
	background: #fff;
	position: absolute;
	/* right:80px; */
	z-index: 999
}

.seventBox {
	padding: 15px
}

.seventTitle {
	display: flex;
	align-items: center;
	margin-bottom: 10px
}

.seventContent {
	display: flex;
	align-items: center;
	margin-bottom: 5px
}

.seventJindu {
	width: 100px;
	height: 10px;
	background: #33CC99;
	margin: 0 10px;
}

.positionImg {
	width: 16px;
	margin-right: 5px
}

.seventActivityBox {
	display: flex;
	justify-content: space-between;
	padding: 15px;
	border-bottom: 1px solid #D5D5D5;
	align-items: center
}

.selectStyle {
	width: 117px;
	height: 27px;
}

.activityBox {
	margin-left: 22px;
	border-bottom: 1px solid #D5D5D5;
	padding-bottom: 10px
}

/deep/ .el-collapse {
	border: none
}

.el-collapse-item /deep/ .el-collapse-item__header {
	height: 10px !important;
	border: none;
	background: #fff;
}

/deep/ .el-icon-arrow-right:before {
	content: ''
}

.charRecord-inner {
	width: 100%;
	background: #ECECEC;
	padding: 10px
}

.charRecord-time {
	margin: 20px 0 0 0;
	text-align: center;
}

.charRecord-leftItem {
	margin: 20px 0 0 0;
	display: flex;
	float: left
}

.charRecord-rightItem {
	margin: 20px 10px 0 0;
	display: flex;
	float: right
}

.charRecord-image {
	width: 42px;
	margin-right: 10px;
}

.charHeadimg {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	float: right;
}

.charRecord-title {
	width: 143px;
	line-height: 42px;
	padding-left: 10px;
	background: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	word-wrap: break-word;
	text-align: justify
}

.charRecord-titlesys {
	margin-right: 100px;
	line-height: 28px;
	padding: 10px 10px 0 10px;
	background: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	word-wrap: break-word;
	text-align: justify
}

.charRecord-title1 {
	width: 15rem;
	line-height: 42px;
	padding-left: 10px;
	background: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	word-wrap: break-word;
	text-align: justify
}

.w-100 {
	width: 100%;
	margin-top: 40rpx;
	position: relative;
	font-size: #333;
}

.w-100::after {
	content: '';
	display: block;
	clear: both;
}

.m-15 {
	margin: 15px;
}

.mb-20 {
	margin-bottom: 20px
}

/deep/ .el-tabs--card>.el-tabs__header {
	border: none
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
	width: 100%;
	display: flex
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
	color: #33CC99;
	flex: 1;
	border-left: 1px solid #33CC99;
	overflow: hidden;
	height: 40px;
	border-bottom: none
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item:before {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 190px;
	border: 1px solid #33CC99;
	border-bottom: none;
	transform-origin: 0 100%;
	transform: skewX(45deg);
	height: 40px;

}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {


	flex: 1;
	border-left: 1px solid #33CC99;
	overflow: hidden;
	height: 40px;
	color: #fff;
	position: relative;
	z-index: 0
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active:before {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 190px;
	border: 1px solid #33CC99;
	border-bottom: none;
	transform-origin: 0 100%;
	transform: skewX(45deg);
	height: 40px;
	background: #33CC99;
	color: #fff;

}

.dialoTitle {
	position: absolute;
	z-index: 999;
	top: 0px;
}

.el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav.is-top {
	border: none !important
}

.enterOrder {
	margin: 20px 0;
	text-align: center;
	cursor: pointer;
}

.enterOrder:hover {
	color: #33CC99
}

.icoImg {
	width: 12px;
	height: 15px
}

.whiteAddress {
	padding-left: 10px;
	border: 1px solid #C0C0C0;
	margin: 10px 10px 20px 10px;
	border-radius: 8px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #c0c0c0;
}

.charRecord-title2 {
	background: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	word-wrap: break-word;
	text-align: justify;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.noOrder {
	margin: 200px 0;
	text-align: center;
	font-size: 18px;
}

.mr-20 {
	margin-right: 20px
}

/deep/.el-dialog {
	margin-top: 60px !important;
}

/deep/.el-dialog .el-dialog__body {
	margin-top: 0;
}

/deep/.el-dialog__body {
	margin-top: 0;
}

/deep/.el-progress-bar__outer {
	background-color: #e9e9e9;
}

/deep/.el-collapse-item__wrap {
	border-bottom: 0;
}

.amap-marker-label {
	border: none;
}

.map_marklist_label {
	border: none;
}
</style>
